<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:title>
        <div>
          <v-select outlined :items="dirList" item-text="text" item-value="value" v-model="type" label="Справочник" hide-details dense class="mt-1" />
        </div>
      </template>
      <template v-slot:actions>
        <v-btn color="primary" @click="$refs.table.updateData()">
          <btn-title icon="fas fa-sync-alt">Обновить</btn-title>
        </v-btn>
        <v-btn v-if="!dirCurrent.readonly && canEdit" color="primary" @click="createNew()" title="Создать элемент">
          <btn-title icon="far fa-plus-square">Создать</btn-title>
        </v-btn>
      </template>
      <portal to="v-main">
        <dir-dialog v-if="showEditDialog" v-model="showEditDialog" :id="idEdit" :api="dirCurrent.api" :dir="dirCurrent" />
      </portal>
      <a-table-f-data2
        v-if="dirCurrent.type == 'data' || false"
        ref="table"
        :dataTable="getData()"
        :model="autoModel || model"
        :useQuery="false"
        :defaults="{
          sort: {},
        }"
      />
      <a-table-f-api v-else ref="table" :api="dirCurrent.api" :model="model" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" :dirCurrent="dirCurrent" :m="m[dirCurrent.name]" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess, genModel } from "@/components/mixings";

export default {
  mixins: [getAccess, genModel],
  components: {
    dirDialog: () => import("./dialogs/dirDialog"),
    ViewItem: () => import("./views/dirView"),
  },
  data() {
    return {
      idEdit: 0,
      idItemShow: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.dirs,
      dirs: this.$store.getters["config/get"].models.dirs.list,
      url: "",
      type: null,
      types: [1],
      autoModel: null,
    };
  },
  created() {
    this.$root.title = "Справочники";
    if (this.$attrs?.dir) {
      this.type = this.dirList.find((el) => el.name == this.$attrs.dir)?.value || null;
    }
    // this.getDirCurrent();
  },
  computed: {
    defaults() {
      return { sort: { key: "id", order: "ASC" }, filters: this.dirCurrent.filters, paramName: this.dirCurrent.name };
    },
    dirList() {
      let list = this.dirs.filter((el) => {
        return this.getAccess("dirs." + el.name + ".view");
      });
      if (!true) {
        let parents = [];
        let listP = [];
        list
          .filter((l) => !!l.parent)
          .forEach((l) => {
            if (parents.includes(l.parent)) {
            } else {
              parents.push(l.parent);
              listP.push(this.dirs.find((d) => d.value == l.parent));
            }
          });
        list = [...listP, ...list];
      }
      return list;
    },
    canEdit() {
      return this.getAccess("dirs." + this.dirCurrent.name + ".create") || (this.dirCurrent?.role || []).includes(this.$root.profile.role);
    },
    dirCurrent() {
      if (!this.type) this.type = this.dirList[0]?.value || null;
      console.log("dir current ", this.type);
      return this.dirList.find((el) => el.value == this.type);
    },
    model() {
      const name = this.dirCurrent?.name || null;
      console.log("model", name);
      if (!name) return {};
      let model = JSON.parse(JSON.stringify(this.m?.[name].list || []));
      model.forEach((el) => {
        if (el?.sourceList) {
          el.options = this.m[name][el.sourceList];
        }
      });
      console.log("return model", model);

      return model;
    },
    tree() {
      if (!true)
        return [
          {
            value: -1111,
            name: "EUROPA",
            children: [],
          },
          {
            value: 4,
            name: "USA",
            children1: [
              { name: "New York", value: 5 },
              { name: "Seatle", value: 6 },
              { name: "Miami", value: 7 },
            ],
          },
          {
            name: "ASIA",
            value: 8,
            children1: [
              { name: "Tokyo", value: 9 },
              { name: "Singapore", value: 10 },
            ],
          },
        ];
      const items = [...this.dirList]; // Копируем массив
      const tree = [];

      // Сопоставляем id родителя с его детьми
      const map = items.reduce((acc, item) => {
        acc[item.value] = item;
        return acc;
      }, {});

      // Связываем родителя с его детьми
      items.forEach((item) => {
        let p = item?.parent || -3;
        if (!item?.parent) {
          tree.push(item);
        } else {
          const parent = map[item.parent];
          if (parent) {
            parent.children = parent.children || [];
            parent.children.push(item);
          }
        }
      });
      console.log("tree", tree);
      return tree;
    },
  },
  watch: {
    dirCurrent(t) {
      if (t && this.$attrs?.dir != t.name) this.$router.push({ query: { dir: t.name } });
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    getData() {
      const name = this.dirCurrent.name;
      let tmp;
      let model = this.model;
      let data = [];
      if (this.dirCurrent?.dir) {
        tmp = this.$root.dirs[name];
      } else {
        const path = this.dirCurrent.path.split(".");
        for (let i = 0; i < path.length; i++) {
          const el = path[i];
          if (i === 0) {
            tmp = JSON.parse(JSON.stringify(this.$store.getters["config/get"] || {}));
          }
          tmp = JSON.parse(JSON.stringify(tmp?.[el] || {}));
        }
      }
      let obj = tmp;
      if (true) {
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            const el = obj[key];
            const row = { id: key };
            if (!model.find((el) => el.name == "id")) {
              model.push({ name: "id", title: "id", type: "string" });
            }
            for (const k in el) {
              row[k] = el[k];
              if (!model.find((el) => el.name == k)) {
                model.push({ name: k, title: k, type: "string" });
              }
            }
            data.push(row);
          }
        }
      }

      this.autoModel = model;
      let res = data.sort((a, b) => {
        return isNaN(a.id) ? (a.id > b.id) - (b.id > a.id) : parseFloat(a.id) > parseFloat(b.id);
      });
      return res;
    },
    getDirCurrent() {
      //  this.dirCurrent = this.dirList.filter((el) => el.value == this.type);
    },
    onClickRow(d) {
      //if (!this.canEdit ) return;
      if (this.dirCurrent.view) this.idItemShow = d.row.id;
      else this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(null);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    itemShowClose() {
      this.loading = true;
      this.$root.title = "Справочники";
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
  },
};
</script>
